<template>
  <n-modal
    v-model:show="showVal"
    :on-update:show="
      (v:boolean) => {
        $emit('modalChange', v);
      }
    "
    class="create-course-modal"
    preset="dialog"
    title="Crear Curso"
    type="info"
    :bordered="false"
    size="huge"
    style="width: 650px"
    :segmented="true"
  >
    <n-space vertical>
      <label for="organizations">Organización <b class="red_color">*</b></label>
      <n-select
        v-model:value="org_id"
        filterable
        placeholder="Organización"
        :options="organizations"
        name="organizations"
      />
      <label for="name">Nombre <b class="red_color">*</b></label>
      <n-input
        v-model:value="name"
        name="name"
        type="text"
        placeholder="Nombre"
      />
      <label for="sence_code">Codigo SENCE</label>
      <n-input
        v-model:value="sence_code"
        name="sence_code"
        type="text"
        placeholder="Codigo SENCE"
      />
      <label for="uploader"
        >Seleccione archivo de elementos<b class="red_color">*</b></label
      >
      <n-upload ref="uploader" :max="1" accept=".json" @change="jsonTreeCicle">
        <n-button>Seleccionar</n-button>
      </n-upload>
      <div id="tavolo"></div>
      <n-alert
        v-if="elements_ready"
        :title="'Curso cuenta con ' + elements.length + ' recursos.'"
        type="success"
      ></n-alert>
      <n-alert
        v-if="!valid_data"
        title="Completa los campos requeridos para continuar"
        type="error"
      ></n-alert>
      <n-button strong secondary type="primary" @click="submitCourse">
        Crear Curso
      </n-button>
    </n-space>
  </n-modal>
</template>
<script lang="ts">
import api from "../providers/ApiProvider";
export default {
  emits: ["modalChange", "courseCreated"],
  created() {
    api.getOrganizations().then((response) => {
      let list = response.data.data;
      this.organizations = this.prepareSelect(list);
      if (this.organizations.length == 1) {
        this.org_id = this.organizations[0].id;
      }
    });
  },
  props: ["show"],
  data(): any {
    return {
      valid_data: true,
      org_id: null,
      course_id: null,
      organizations: [],
      clients: [],
      elements: [],
      elements_ready: false,
      tree_json: "",
      sence_code: "",
      name: "",
    };
  },
  methods: {
    validateFields() {
      /*
      client_id: null,
      org_id: null,
      course_id: null,
      */
      return this.elements_ready && this.org_id !== null && this.name !== null;
    },
    submitCourse() {
      this.valid_data = this.validateFields();
      if (this.valid_data) {
        let post_data = {
          name: this.name,
          org_id: this.org_id,
          sence_code: this.sence_code,
          tree_json: this.tree_json,
        };
        console.log(post_data);
        api.createCourse(post_data).then((res) => {
          console.log(res);
          this.$emit("courseCreated", res);
          this.cancelFile();
          this.name = null;
          this.sence_code = null;
          this.org_id = null;
          this.tree_json = null;
          this.elements = [];
          this.elements_ready = false;
        });
      }
    },
    cancelFile() {
      this.$refs.uploader.clear();
      this.elements_ready = false;
      this.elements = [];
    },
    jsonTreeCicle(op) {
      if (op.event) {
        let file = op.file.file;
        var reader = new FileReader();
        reader.onload = function (this: any, e) {
          var data = e.target ? e.target.result : e.target;
          this.tree_json = String.fromCharCode.apply(
            null,
            Array.from(new Uint8Array(data))
          );
          this.elements = [].concat(JSON.parse(this.tree_json));
          this.elements_ready = true;
          /* reader.readAsArrayBuffer(file) -> data will be an ArrayBuffer */
          //container.innerHTML = XLSX.utils.sheet_to_html(worksheet);
          /* DO SOMETHING WITH workbook HERE */
        }.bind(this);
        reader.readAsArrayBuffer(file);
      } else {
        this.tree_schemed = false;
      }
    },
    prepareSelect(data) {
      let final_list: any = [];
      data.forEach((org) => {
        org.label = org.name;
        org.value = org.id;
        final_list.push(org);
      });
      return final_list;
    },
  },
  computed: {
    showVal() {
      return this.show;
    },
  },
};
</script>
<style>
.red_color {
  color: red;
}
.minimal_select {
  width: 100% !important;
  background: #2080f030;
}
.excel_table td {
  border: 0.5px solid black;
}
.excel_table {
  border-collapse: collapse;
  max-width: 90vw;

  font-size: 10px !important;
}
.excel_table_container {
  max-width: 100%;
  overflow-x: auto;
}
.scheme_resume {
  height: calc(100% - 280px);
}
.scheme_resume .n-grid {
  height: calc(100% - 20px);
}
/*tr.invalid_row td {
  background: #d030507a !important;
  color: black !important;
}
tr.valid_row td {
  background: #18a05852 !important;
  color: black !important;
}*/
.small_alert.n-alert .n-alert-body {
  padding: 5px;
}
tr.invalid_row td {
  background: #d74f6b !important;
}
.n-dialog.n-modal {
  overflow-x: auto;
}
.resume_buttons {
  margin-top: 15px;
  border-top: 1px solid #9ea0a1;
  padding: 5px 10px 10px 0;
}
.resume_buttons button {
  margin-right: 10px;
}
</style>
