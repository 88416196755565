<script setup lang="ts">
import api from "../providers/ApiProvider";
</script>
<template>
 <n-modal
    v-model:show="showVal"
    :on-update:show="
      (v:boolean) => {
        $emit('modalChange', v);
      }
    "
    class="create-course-modal"
    preset="dialog"
    title="Editar Curso"
    type="info"
    :bordered="false"
    size="huge"
    style="width: 650px"
    :segmented="true"
  >
    <n-space vertical>
      <label for="organizations">Organización <b class="red_color">*</b></label>
      <n-select
        v-model:value="org_id"
        filterable
        placeholder="Organización"
        :options="organizations"
        name="organizations"
      />
      <label for="name">Nombre <b class="red_color">*</b></label>
      <n-input
        v-model:value="name"
        name="name"
        type="text"
        placeholder="Nombre"
      />
      <label for="sence_code">Codigo SENCE</label>
      <n-input
        v-model:value="sence_code"
        name="sence_code"
        type="text"
        placeholder="Codigo SENCE"
      />
      <label for="status">Estado</label>
      <n-switch v-model:value="status" />
      <div id="tavolo"></div>
      <n-alert
        v-if="!valid_data"
        title="Completa los campos requeridos para continuar"
        type="error"
      ></n-alert>
      <n-button strong secondary type="primary" @click="submitCourse">
        Editar Curso
      </n-button>
    </n-space>
  </n-modal>
</template>
<script lang="ts">
export default {
  emits: ["modalChange", "courseEdited"],
  created() {
    api.getOrganizations().then((response) => {
      let list = response.data.data;
      this.organizations = this.prepareSelect(list);
    });
  },
  props: ["show", "course"],
  data(): any {
    return {
      valid_data: true,
      org_id: null,
      course_id: null,
      organizations: [],
      elements: [],
      elements_ready: false,
      tree_json: "",
      sence_code: "",
      name: "",
      status: false,
      courses: [],
      rowscols: {},
      code: "",
    };
  },
  methods: {
    validateFields() {
      return (
        this.org_id !== null && this.name !== null
      );
    },
    submitCourse() {
      this.valid_data = this.validateFields();
      if (this.valid_data) {
        let post_data = {
          course_id: this.course_id,
          org_id: this.org_id,
          client: this.client_id,
          code: this.code,
          status: this.status ? 1 : 0,
        };
        console.log(post_data);
        api.updateOrder(this.course.id, post_data).then((res) => {
          console.log(res);
          this.$emit("courseEdited", res);
        });
      }
    },
    prepareSelect(data) {
      let final_list: any = [];
      data.forEach((org) => {
        org.label = org.name;
        org.value = org.id;
        final_list.push(org);
      });
      return final_list;
    },
  },
  computed: {
    showVal() {
      return this.show;
    }
  },
  watch: {
    course(v) {
      if (v) {
        let selected_org = this.course
          ? this.organizations.filter((x) => {
              console.log(this.course);
              return x.id == this.course.org_id;
            })[0]
          : null;
        if (selected_org) {
          this.org_id = selected_org.id;
          this.name = this.course.name;
          this.sence_code = this.course.sence_code;
          this.status = this.course.status == 1;
        }
      }
    },
  },
};
</script>
<style>
.red_color {
  color: red;
}

.minimal_select {
  width: 100% !important;
  background: #2080f030;
}

.excel_table td {
  border: 0.5px solid black;
}

.excel_table {
  border-collapse: collapse;
  max-width: 90vw;

  font-size: 10px !important;
}

.excel_table_container {
  max-width: 100%;
  overflow-x: auto;
}

.scheme_resume {
  height: calc(100% - 280px);
}

.scheme_resume .n-grid {
  height: calc(100% - 20px);
}

/*tr.invalid_row td {
  background: #d030507a !important;
  color: black !important;
}
tr.valid_row td {
  background: #18a05852 !important;
  color: black !important;
}*/
.small_alert.n-alert .n-alert-body {
  padding: 5px;
}

tr.invalid_row td {
  background: #d74f6b !important;
}

.n-dialog.n-modal {
  overflow-x: auto;
}

.resume_buttons {
  margin-top: 15px;
  border-top: 1px solid #9ea0a1;
  padding: 5px 10px 10px 0;
}

.resume_buttons button {
  margin-right: 10px;
}
</style>
