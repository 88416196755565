<template>
  <n-modal
    v-model:show="showVal"
    :on-update:show="
      (v:boolean) => {
        $emit('modalChange', v);
      }
    "
    class="create-order-modal"
    preset="dialog"
    title="Crear Orden"
    type="info"
    :bordered="false"
    size="huge"
    style="width: 650px"
    :segmented="true"
  >
    <n-alert
      class="small_alert"
      type="warning"
      :show-icon="false"
      v-if="currentOrganizationCupons !== false"
    >
      La organización cuenta con <b>{{ currentOrganizationCupons }}</b> cupo(s),
      puede crear ordenes siempre que el numero de participantes no exceda el
      cupo disponible.
    </n-alert>
    <n-space vertical>
      <label for="organizations">Organización <b class="red_color">*</b></label>
      <n-select
        v-model:value="org_id"
        filterable
        placeholder="Organización"
        :options="organizations"
        name="organizations"
        @update:value="updateOrg"
      />
      <label for="clients">Cliente <b class="red_color">*</b></label>
      <n-select
        v-model:value="client_id"
        filterable
        placeholder="Cliente"
        tag
        :options="clients"
        name="clients"
      />
      <label for="courses">Curso(s) <b class="red_color">*</b></label>
      <n-select
        v-model:value="course_ids"
        filterable
        multiple 
        placeholder="Curso"
        :options="FilteredCourses"
        name="courses"
      />

      <label for="OT">OT</label>
      <n-input
        v-model:value="code"
        name="OT"
        type="text"
        placeholder="Orden trabajo interna"
      />
      <label for="OT"
        >Seleccione archivo de participantes<b class="red_color">*</b></label
      >
      <n-upload
        ref="uploader"
        :max="1"
        accept=".csv,.xls,.xlsx"
        @change="participantCicle"
      >
        <n-button>Seleccionar</n-button>
      </n-upload>
      <div id="tavolo"></div>
      <n-alert
        v-if="participants_ready"
        :title="
          final_participants.length -
          invalidCount +
          ' Participantes seleccionados'
        "
        type="success"
      ></n-alert>
      <n-alert
        v-if="!valid_data"
        title="Completa los campos requeridos para continuar"
        type="error"
      ></n-alert>
      <n-button strong secondary type="primary" @click="submitOrder">
        Crear Orden
      </n-button>
    </n-space>
  </n-modal>
  <n-modal
    v-model:show="showExcel"
    class="create-order-modal"
    preset="dialog"
    title="Seleccione columnas"
    type="info"
    :bordered="false"
    size="huge"
    style="width: 90vw; height: 90vh"
    :segmented="true"
  >
    <div class="excel_table_container">
      <n-checkbox v-model:checked="ignore_header">
        Primera fila es cabecera
      </n-checkbox>
      <table class="excel_table">
        <tr>
          <td v-for="(col, index) in rowscols.rows[1].length" :key="index">
            <select
              v-model="col_index[index]"
              @change="changeInput(index)"
              class="minimal_select"
            >
              <option value=""></option>
              <option value="rut" :disabled="rut_schemed">RUT</option>
              <option value="name" :disabled="name_schemed">Nombre</option>
            </select>
            <b class="row_header">{{
              rowscols.cols[index] ? rowscols.cols[index].value : ""
            }}</b>
          </td>
        </tr>
        <tr v-for="index in 5" :key="index">
          <td v-for="(value, iindex) in rowscols.rows[index]" :key="iindex">
            {{ value }}
          </td>
        </tr>
      </table>
      <font>
        Solo se muestran las primeras 5 lineas.<b class="red_color">*</b>
      </font>
    </div>
    <div v-if="rut_schemed && name_schemed" class="scheme_resume">
      <n-grid x-gap="12" :cols="3">
        <n-gi span="1">
          <h5 class="subtitle">Resumen:</h5>
          <n-alert
            :title="resumeData.length - invalidCount + ' Participantes validos'"
            type="success"
          ></n-alert>
          <n-alert
            :title="invalidCount + ' Participantes invalidos'"
            type="error"
          ></n-alert>
          <div class="resume_buttons">
            <n-button
              strong
              secondary
              type="primary"
              @click="submitParticipants"
            >
              Guardar y volver
            </n-button>
            <n-button strong secondary @click="cancelFile"> Volver </n-button>
          </div>
        </n-gi>
        <n-gi span="2">
          <h5 class="subtitle">Participantes detectados:</h5>
          <n-checkbox v-model:checked="invalid_only">
            Mostrar solo valores invalidos
          </n-checkbox>
          <n-data-table
            class="clean_table"
            :columns="resume_columns"
            :data="resumeData"
            :min-height="160"
            :flex-height="true"
            :pagination="{ pageSize: 10 }"
            :row-class-name="rowClassName"
          />
        </n-gi>
      </n-grid>
      <n-alert
        class="small_alert"
        type="error"
        :show-icon="false"
        v-if="notEnoughtCupons"
      >
        La lista de participantes excede los cupos disponibles de tu
        organización (<b>{{ currentOrganizationCupons }}</b
        >).
      </n-alert>
    </div>
  </n-modal>
</template>
<script lang="ts">
import api from "../providers/ApiProvider";
import utils from "../providers/UtilsProvider";
import * as XLSX from "xlsx";

export default {
  emits: ["modalChange", "orderCreated"],
  created() {
    api.getOrganizations().then((response) => {
      let list = response.data.data;
      this.organizations = this.prepareSelect(list);
      if (this.organizations.length == 1) {
        this.org_id = this.organizations[0].id;
        this.setClients(this.org_id, this.organizations[0]);
      }
    });
    api.getCourses().then((response) => {
      let list = response.data.data;
      this.courses = this.prepareSelectCourse(list);
    });
  },
  props: ["show"],
  data(): any {
    return {
      ignore_header: true,
      valid_data: true,
      final_participants: [],
      participants_ready: false,
      rut_schemed: false,
      rut_position: null,
      name_schemed: false,
      name_position: null,
      invalid_only: false,
      col_index: [],
      showExcel: false,
      client_id: null,
      org_id: null,
      course_ids: [],
      organizations: [],
      clients: [],
      courses: [],
      rowscols: {},
      code: "",
      resume_columns: [
        {
          title: "#",
          key: "index",
          width: "40",
        },
        {
          title: "RUT",
          key: "rut",
        },
        {
          title: "Nombre",
          key: "name",
        },
      ],
    };
  },
  methods: {
    validateFields() {
      /*
      client_id: null,
      org_id: null,
      course_ids: null,
      */
      return (
        this.participants_ready &&
        this.client_id !== null &&
        this.org_id !== null &&
        this.course_ids.length >= 1
      );
    },
    submitOrder() {
      this.valid_data = this.validateFields();
      if (this.valid_data) {
        let post_data = {
          participants: this.final_participants,
          course_ids: this.course_ids,
          org_id: this.org_id,
          client: this.client_id,
          code: this.code,
        };
        console.log(post_data);
        api.createOrder(post_data).then((res) => {
          console.log(res);
          this.$emit("orderCreated", res);
          this.cancelFile();
          this.participants_ready = false;
          this.client_id = null;
          this.code = null;
          this.org_id = null;
          this.course_ids = null;
        });
      }
    },
    submitParticipants() {
      this.invalid_only = false;
      this.final_participants = this.resumeData.map((x) => {
        return { rut: x.rut, name: x.name };
      });
      this.participants_ready = true;
      this.showExcel = false;
    },
    cancelFile() {
      this.showExcel = false;
      this.$refs.uploader.clear();
      this.rut_schemed = false;
      this.rut_position = null;
      this.name_schemed = false;
      this.name_position = null;
      this.col_index = [];
    },
    updateOrg(o, v) {
      this.client_id = null;
      this.course_ids = null;
      this.setClients(v.id, v);
    },
    participantCicle(op) {
      try {
        if (op.event) {
          (this.final_participants = []), (this.participants_ready = false);
          let file = op.file.file;
          var reader = new FileReader();
          reader.onload = function (this: any, e) {
            var data = e.target ? e.target.result : e.target;
            /* reader.readAsArrayBuffer(file) -> data will be an ArrayBuffer */
            var workbook = XLSX.read(data);
            //var container = document.getElementById("tavolo");
            let worksheet = workbook.Sheets[workbook.SheetNames[0]];
            this.rowscols = this.getRowsCols(worksheet);
            this.showExcel = true;
            //container.innerHTML = XLSX.utils.sheet_to_html(worksheet);
            /* DO SOMETHING WITH workbook HERE */
          }.bind(this);
          reader.readAsArrayBuffer(file);
        } else {
          this.rut_schemed = false;
          this.name_schemed = false;
          this.rut_position = null;
          this.name_position = null;
        }
      } catch (e) {
        this.rut_schemed = false;
        this.name_schemed = false;
        this.rut_position = null;
        this.name_position = null;
      }
    },
    setClients(v, o) {
      if (o) {
        this.clients = this.prepareSelect(o.clients);
      }
    },
    prepareSelectCourse(data) {
      let final_list: any = [];
      data.forEach((org) => {
        org.label =
          "(" +
          (org.sence_code ? org.sence_code : "Pendiente") +
          ") " +
          org.name;
        org.value = org.id;
        final_list.push(org);
      });
      return final_list;
    },
    prepareSelect(data) {
      let final_list: any = [];
      data.forEach((org) => {
        org.label = org.name;
        org.value = org.id;
        final_list.push(org);
      });
      return final_list;
    },
    getRowsCols(sheet) {
      const rows: any = XLSX.utils.sheet_to_json(sheet, { header: 1 });
      let cols: any = [];
      for (let row of rows) {
        const keys = Object.keys(row);
        if (keys.length > cols.length) {
          cols = keys.map((key) => {
            return {
              field: key,
              label: XLSX.utils.encode_col(parseInt(key)),
              value: row[key],
            };
          });
        }
      }
      return { rows, cols };
    },
    changeInput() {
      this.rut_schemed = false;
      this.name_schemed = false;
      this.col_index.forEach((v, i) => {
        if (v == "rut") {
          this.rut_schemed = true;
          this.rut_position = i;
        }
        if (v == "name") {
          this.name_schemed = true;
          this.name_position = i;
        }
      });
    },
    rowClassName(row) {
      return !row.valid_rut || !row.valid_name || row.duplicated
        ? "invalid_row"
        : "valid_row";
    },
  },
  computed: {
    showVal() {
      return this.show;
    },
    FilteredCourses() {
      return this.courses.filter((e) => {
        return e.org_id == this.org_id;
      });
    },
    notEnoughtCupons() {
      return (
        this.currentOrganizationCupons !== false &&
        this.currentOrganizationCupons <
          this.resumeData.length - this.invalidCount
      );
    },
    currentOrganizationCupons() {
      let finder = this.organizations.filter(
        ((x) => {
          return x.id == this.org_id;
        }).bind(this)
      );
      return finder.length == 1 && finder[0].participants_max !== 0
        ? finder[0].participants_max - finder[0].participants_count
        : false;
    },
    invalidCount() {
      return this.resumeData.filter((x) => {
        return !x.valid_name || !x.valid_rut || x.duplicated;
      }).length;
    },
    resumeData() {
      if (this.name_schemed && this.rut_schemed) {
        let finals: Array<any> = [];
        let finals_repeat: any = {};
        this.rowscols.rows.forEach((row) => {
          if (row[this.rut_position]) {
            let rut = utils.cleanRut(row[this.rut_position]);
            if (finals_repeat[rut]) {
              finals_repeat[rut] = finals_repeat[rut] + 1;
            } else {
              finals_repeat[rut] = 1;
            }
          }
        });
        this.rowscols.rows.forEach(
          ((row, index) => {
            if (index == 0) {
              if (this.ignore_header) {
                return;
              }
            }
            if (row[this.rut_position]) {
              let valid_rut =
                row[this.rut_position] &&
                utils.validateRut(row[this.rut_position]);
              let valid_name =
                row[this.name_position] &&
                utils.validateName(row[this.name_position]);
              let p_row = {
                rut: valid_rut
                  ? utils.cleanRut(row[this.rut_position])
                  : row[this.rut_position],
                name: valid_name
                  ? utils.cleanName(row[this.name_position])
                  : row[this.name_position],
                index: index + 1, // representar linea en excel
                valid_name,
                valid_rut,
                duplicated: false,
              };
              p_row.duplicated = finals_repeat[p_row.rut] > 1;

              if (this.invalid_only) {
                if (!valid_rut || !valid_name || p_row.duplicated) {
                  finals.push(p_row);
                }
              } else {
                if (valid_rut || valid_name || !p_row.duplicated) {
                  finals.push(p_row);
                }
              }
            }
          }).bind(this)
        );
        return finals;
      }
      return [];
    },
  },
};
</script>
<style>
.red_color {
  color: red;
}
.minimal_select {
  width: 100% !important;
  background: #2080f030;
}
.excel_table td {
  border: 0.5px solid black;
}
.excel_table {
  border-collapse: collapse;
  max-width: 90vw;

  font-size: 10px !important;
}
.excel_table_container {
  max-width: 100%;
  overflow-x: auto;
}
.scheme_resume {
  height: calc(100% - 280px);
}
.scheme_resume .n-grid {
  height: calc(100% - 20px);
}
/*tr.invalid_row td {
  background: #d030507a !important;
  color: black !important;
}
tr.valid_row td {
  background: #18a05852 !important;
  color: black !important;
}*/
.small_alert.n-alert .n-alert-body {
  padding: 5px;
}
tr.invalid_row td {
  background: #d74f6b !important;
}
.n-dialog.n-modal {
  overflow-x: auto;
}
.resume_buttons {
  margin-top: 15px;
  border-top: 1px solid #9ea0a1;
  padding: 5px 10px 10px 0;
}
.resume_buttons button {
  margin-right: 10px;
}
</style>
