class UtilsProvider {
  validateRut(rut) {
    let digit, i, total, ver_digit, _i, _len;
    if (typeof rut === "undefined") {
      return false;
    }
    rut = rut.toLowerCase().replace(/[.-]/g, "");
    const reverse_str = function (str) {
      return str.split("").reverse().join("");
    };
    const rut_digit = rut.substr(rut.length - 1);
    const rut_splited = rut.substr(0, rut.length - 1);

    const rut_reversed = reverse_str(rut_splited);
    const mult_nums = [2, 3, 4, 5, 6, 7, 2, 3, 4, 5, 6, 7];
    const rut_to_process = rut_reversed.split("");
    total = 0;
    for (i = _i = 0, _len = rut_to_process.length; _i < _len; i = ++_i) {
      digit = rut_to_process[i];
      total = total + digit * mult_nums[i];
    }
    const rest = total % 11;
    ver_digit = 11 - rest;
    if (ver_digit === 11) {
      ver_digit = 0;
    } else if (ver_digit === 10) {
      ver_digit = "k";
    }
    if (rut_digit === ver_digit || parseInt(rut_digit) === ver_digit) {
      return true;
    }
    return false;
  }
  cleanRut(rut) {
    let res = rut.replace(/\./g, "");
    res = res.replace(" ", "").toUpperCase();
    return res;
  }
  validateName(name) {
    return name.length >= 3;
  }
  cleanName(name) {
    return this.capitalize(name);
  }
  capitalize(str) {
    return str
      .trim()
      .toLowerCase()
      .replace(/\w\S*/g, (w) => w.replace(/^\w/, (c) => c.toUpperCase()));
  }
  mergeAuxiliaryParticipants(base) {
    const auxiliarys = [
      { code: "11111111-1", name: "AUXILIAR" },
      { code: "22222222-2", name: "AUXILIAR 2" },
      { code: "12222323-K", name: "AUXILIAR 3" },
      { code: "33333333-3", name: "AUXILIAR 4" },
      { code: "23333333-6", name: "AUXILIAR 5" },
      { code: "12222222-5", name: "AUXILIAR 6" },
      { code: "17777777-3", name: "AUXILIAR 7" },
      { code: "25555555-3", name: "AUXILIAR 8" },
      { code: "12000000-4", name: "AUXILIAR 9" },
      { code: "21000000-3", name: "AUXILIAR 10" },
    ];
    return base.concat(auxiliarys);
  }
}
export default new UtilsProvider();
