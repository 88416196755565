<script lang="ts">
import api from "../providers/ApiProvider";
import utils from "../providers/UtilsProvider";
import auth from "../providers/AuthProvider";
import CreateOrderModal from "../components/CreateOrderModal.vue";
import EditOrderModal from "../components/EditOrderModal.vue";
import ShowParticipantsModal from "../components/ShowParticipantsModal.vue";
import Swal from "sweetalert2";
import { h } from "vue";
import { NButton, NTag } from "naive-ui";
export default {
  components: { CreateOrderModal, EditOrderModal, ShowParticipantsModal },
  created() {
    api.getOrganizations().then((response) => {
      let list = response.data.data;
      this.organizations = this.prepareSelect(list);
      if (this.organizations.length == 1) {
        this.selectedOrg = this.organizations[0].id;
        this.setClients(this.selectedOrg, this.organizations[0]);
        this.setupOrders();
      } else {
        this.setupOrders();
      }
    });
    this.orderListColumns = [
      {
        title: "#",
        key: "id",
        width: 45,
        align: "center",
      },
      {
        title: "OT",
        key: "code",
        width: 80,
      },
      {
        title: "Curso",
        key: "course.name",
        render(row) {
          return (
            (row.course.sence_code ? " (" + row.course.sence_code + ") " : "") +
            row.course.name
          );
        },
      },
      {
        title: "Cliente",
        key: "client.name",
      },
      {
        title: "N",
        key: "number_participants",
        width: 30,
      },
      {
        title: "Fecha",
        key: "created_at",
        width: 85,
        align: "center",
      },
      {
        title: "Estado",
        key: "status",
        align: "center",
        width: 70,
        render(row) {
          let status_map = {
            0: { tag_type: "warning", text: "Pendiente" },
            1: { tag_type: "success", text: "Activo" },
            2: { tag_type: "info", text: "Terminado" },
          };
          let current_status = status_map[row.status];
          return [
            h(
              NTag,
              {
                strong: true,
                tertiary: true,
                type: current_status.tag_type,
              },
              { default: () => current_status.text }
            ),
          ];
        },
      },
      {
        title: "",
        key: "Reporte",
        render: function (this: any, row) {
          return [h(
              NButton,
              {
                strong: true,
                tertiary: false,
                size: "small",
                onClick: () => {
                  window.open(
                    "https://api.adistancia.app/reports/download/all?order=" + row.id
                  );
                },
              },
              { default: () => "Reporte" }
            ),
            h(
              NButton,
              {
                strong: true,
                tertiary: true,
                size: "small",
                class: "spacer",
                onClick: function (this: any) {
                  this.showParticipantModal(row);
                }.bind(this),
              },
              { default: () => "Ver Participantes" }
            ),
            !auth.can("update-orders")
              ? null
              : h(
                  NButton,
                  {
                    strong: true,
                    tertiary: true,
                    size: "small",
                    class: "spacer",
                    type: "info",
                    onClick: function (this: any) {
                      this.editModal(row);
                    }.bind(this),
                  },
                  { default: () => "Editar" }
                ),
            !auth.can("delete-orders")
              ? null
              : h(
                  NButton,
                  {
                    strong: true,
                    tertiary: true,
                    class: "spacer",
                    size: "small",
                    type: "error",
                    onClick: function (this: any) {
                      this.deleteModal(row);
                    }.bind(this),
                  },
                  { default: () => "Eliminar" }
                ),
            !auth.can("create-orders")
              ? null
              : h(
                  NButton,
                  {
                    strong: true,
                    tertiary: true,
                    size: "small",
                    class: "spacer",
                    type: "tertiary",
                    onClick: function (this: any) {
                      this.downloadSetupJSON(row);
                    }.bind(this),
                  },
                  { default: () => "JSON" }
                ),
          ];
        }.bind(this),
      },
    ];
  },
  data(): any {
    return {
      auth: auth,
      showCreateModal: false,
      showEditModal: false,
      showParticipantsModal: false,
      currentOrder: null,
      ordersLoaded: false,
      selectedState: -1,
      selectedOrg: null,
      selectedClient: -1,
      selectedOrder: null,
      facturaHasta: null,
      facturaDesde: null,
      searchParticipant: "",
      organizations: [],
      clients: [{ value: -1, label: "Todos los clientes" }],
      orders: [],
      pagination: {
        pageSize: 10,
      },
      orderListColumns: [],
      states: [
        {
          value: -1,
          label: "Todos los estados",
        },
        {
          value: 0,
          label: "Pendiente",
        },
        {
          value: 1,
          label: "Activo",
        },
      ],
    };
  },
  methods: {
    createFactura() {
      let params = {
        organizationId: this.selectedOrg,
        orderStart: this.facturaDesde,
        orderEnd: this.facturaHasta,
      };
      window.open(
        "https://api.adistancia.app/reports/download/factura?" +
          new URLSearchParams(params).toString()
      );
    },
    downloadSetupJSON(row) {
      api.getOrder(row.id, true).then((response) => {
        let data = response.data.data;
        let setup_data = {
          endpoint: "https://api.adistancia.app/api/report",
          order_id: row.id,
          participants: utils.mergeAuxiliaryParticipants(
            data.participants_setup
          ),
        };
        const blob = new Blob(
          ["var setup = JSON.parse('" + JSON.stringify(setup_data) + "');"],
          {
            type: "application/javascript",
          }
        );

        const elem = window.document.createElement("a");
        elem.href = window.URL.createObjectURL(blob);
        elem.download = "setup.js";
        document.body.appendChild(elem);
        elem.click();
        document.body.removeChild(elem);
        console.log(setup_data);
      });
    },
    setClientChange(v) {
      this.selectedClient = v;
      this.setOrders();
    },
    setStateChange(v) {
      this.selectedState = v;
      this.setOrders();
    },
    createModal() {
      console.log("createModal");
      this.showCreateModal = true;
    },
    CreateModalChange(v) {
      this.showCreateModal = v;
    },
    EditModalChange(v) {
      this.showEditModal = v;
    },
    ShowParticipantsModalChange(v) {
      this.showParticipantsModal = v;
    },
    OnOrderEdited() {
      this.showEditModal = false;
      Swal.fire({
        title: "Orden actualizada!",
        text: "Se actualizo con exito la orden.",
        icon: "success",
        didClose: () => {
          this.setupOrders();
        },
      });
    },
    OnOrderCreated() {
      this.showCreateModal = false;
      Swal.fire({
        title: "Orden creada!",
        text: "Se creo con exito la orden.",
        icon: "success",
        didClose: () => {
          this.setupOrders();
        },
      });
    },
    deleteModal(row) {
      Swal.fire({
        title: "¿Esta seguro?",
        text: "No podra revertir esto!, se borraran los reportes asociados a esta orden.",
        icon: "warning",
        showCancelButton: true,
        confirmButtonText: "Si, borrar orden",
      }).then((result) => {
        if (result.isConfirmed) {
          api.deleteOrder(row.id).then((response) => {
            console.log(response);
            Swal.fire("Eliminado", "Se borro con exito", "success");
            this.setupOrders();
          });
        }
      });
    },
    showParticipantModal(row) {
      this.currentOrder = row;
      this.showParticipantsModal = true;
      console.log(row);
    },
    editModal(row) {
      this.currentOrder = row;
      this.showEditModal = true;
    },
    setClients(v, o) {
      if (o) {
        this.selectedOrg = v;
        //this.selectedOrg = o.id;
        this.orders = [];
        this.clients = [{ value: -1, label: "Todos los clientes" }].concat(
          this.prepareSelect(o.clients)
        );
        this.setOrders();
      }
    },
    setupOrders() {
      this.ordersLoaded = false;
      api.getOrders().then((response) => {
        let list = response.data.data;
        this.orders = list;
        this.ordersLoaded = true;
      });
    },
    setOrders() {
      this.ordersLoaded = false;
      api
        .getOrders({
          organizationId: this.selectedOrg,
          clientId: this.selectedClient,
          participantSearch: this.searchParticipant,
          status: this.selectedState,
        })
        .then((response) => {
          let list = response.data.data;
          this.orders = list;
          this.ordersLoaded = true;
        });
    },
    prepareSelect(data) {
      let final_list: any = [];
      data.forEach((org) => {
        org.label = org.name;
        org.value = org.id;
        final_list.push(org);
      });
      return final_list;
    },
  },
};
</script>
<template>
  <main>
    <n-space vertical>
      <span><b> Filtros</b></span>
      <n-grid x-gap="12" :cols="2">
        <n-gi>
          <n-space vertical>
            <h5 class="subtitle">Organización:</h5>
            <n-select
              :value="selectedOrg"
              filterable
              placeholder="Organizacion"
              :options="organizations"
              @update:value="setClients"
            />
            <h5 class="subtitle">Participante:</h5>
            <div class="n-input__input">
              <input
                type="text"
                v-model="searchParticipant"
                @change="setOrders"
                class="n-input__input-el"
                placeholder="Nombre o RUT participante"
                size="20"
                style="
                  width: 100%;
                  margin: 0 !important;
                  padding: 0px !important;
                  height: 30px;
                  border: 1px solid #e0e0e6;
                  text-indent: 10px;
                  color: black !important;
                "
              />
            </div>
            <!--  <n-input
              v-model:value="searchParticipant"
              type="text"
              placeholder="Nombre o RUT participante"
              
            /> -->
          </n-space>
        </n-gi>
        <n-gi>
          <n-space vertical>
            <h5 class="subtitle">Cliente:</h5>
            <n-select
              :value="selectedClient"
              filterable
              placeholder="Cliente"
              :options="clients"
              @update:value="setClientChange"
            />
            <h5 class="subtitle">Estado:</h5>
            <n-select
              :value="selectedState"
              filterable
              placeholder="Estado"
              :options="states"
              @update:value="setStateChange"
            />
          </n-space>
        </n-gi>
      </n-grid>
      <hr />
      <div
        v-if="auth.can('create-orders')"
        style="display: flex; align-items: end; justify-content: end"
      >
        <n-button strong secondary type="success" @click="createModal()"
          >Añadir Orden</n-button
        >
      </div>
      <div v-if="auth.can('create-orders')">
        <div class="n-input__input">
          <input
            type="text"
            v-model="facturaDesde"
            class="n-input__input-el"
            placeholder="Desde"
            size="20"
            style="
              width: 100%;
              margin: 0 !important;
              padding: 0px !important;
              height: 30px;
              border: 1px solid #e0e0e6;
              text-indent: 10px;
              color: black !important;
            "
          />
        </div>
        <div class="n-input__input">
          <input
            type="text"
            v-model="facturaHasta"
            class="n-input__input-el"
            placeholder="Hasta"
            size="20"
            style="
              width: 100%;
              margin: 0 !important;
              padding: 0px !important;
              height: 30px;
              border: 1px solid #e0e0e6;
              text-indent: 10px;
              color: black !important;
            "
          />
        </div>
        <n-button strong secondary type="success" @click="createFactura()"
          >Exportar factura</n-button
        >
      </div>
      <n-alert
        v-if="selectedClient && !ordersLoaded"
        title="Buscando Ordenes"
        type="info"
        >Obteniendo listado de ordenes asociadas a cliente
        <n-spin class="spinner" size="small"
      /></n-alert>
      <n-alert
        v-if="ordersLoaded && orders.length == 0"
        title="Sin Ordenes"
        type="info"
        >No tiene ordenes asociadas.</n-alert
      >
      <div v-if="orders.length >= 1">
        <n-data-table
          :columns="orderListColumns"
          :data="orders"
          :pagination="pagination"
          :bordered="true"
          :single-line="false"
        />
      </div>
    </n-space>
    <!-- MODALS -->
    <Teleport to="body">
      <CreateOrderModal
        :show="showCreateModal"
        @modalChange="CreateModalChange"
        @orderCreated="OnOrderCreated"
      ></CreateOrderModal>
      <EditOrderModal
        :show="showEditModal"
        :order="currentOrder"
        @modalChange="EditModalChange"
        @orderEdited="OnOrderEdited"
      ></EditOrderModal>
      <ShowParticipantsModal
        :show="showParticipantsModal"
        :order="currentOrder"
        @modalChange="ShowParticipantsModalChange"
      ></ShowParticipantsModal>
    </Teleport>
  </main>
</template>
<style>
:root {
  --n-td-padding: 5px !important;
}
.n-data-table .n-data-table-td {
  padding: 5px !important;
}
.n-spin-body.spinner {
  float: right;
  margin-top: -15px;
}
.subtitle {
  margin: 10px 0 0 0;
}
.n-button.spacer {
  margin: 5px !important;
}
</style>
