<template>
  <n-space vertical size="large">
    <n-layout>
      <n-layout-content
        content-style="padding: 24px; display: flex; justify-content: center"
      >
        <n-card title="">
          <img
            class="login_logo"
            src="@/assets/imgs/logo.png"
            alt="Logo"
            width="50"
          />
          <n-form ref="formRef">
            <n-form-item path="email" label="Correo Electronico">
              <n-input placeholder="Correo Electronico" v-model:value="email" />
            </n-form-item>
            <n-form-item path="password" label="Contraseña">
              <n-input
                type="password"
                placeholder="Contraseña"
                v-model:value="password"
              />
            </n-form-item>
            <n-row :gutter="[0, 24]">
              <n-col :span="24">
                <div style="display: flex; justify-content: flex-end">
                  <n-button round type="primary" @click="login"
                    >Iniciar Sesión
                  </n-button>
                </div>
              </n-col>
            </n-row>
          </n-form>
          <n-alert v-if="loginError" title="Error" type="error"
            >Correo Electronico o contraseña incorrectos</n-alert
          >
        </n-card>
      </n-layout-content>
    </n-layout>
  </n-space>
</template>
<script>
import auth from "../providers/AuthProvider";
export default {
  created(){
    if(auth.isLoggedIn()){
      this.$router.push("/");
    }
  },
  data: function () {
    return {
      email: "",
      password: "",
      loginError: false,
    };
  },
  methods: {
    login() {
      this.loginError = false;
      auth
        .login({ email: this.email, password: this.password })
        .then((res) => {
          console.log(res);
          let target = this.$route.query.redirect ? this.$route.query.redirect : "/";
          this.$emit('reload');
          this.$router.replace(target);
          
        })
        .catch((error) => {
          console.log(error);
          this.loginError = true;
        });
    },
  },
};
</script>
<style>
.n-alert {
  margin-top: 15px;
}
.n-card {
  max-width: 400px;
  margin-top: 50px;
}
.login_logo {
  margin: 0 auto;
  display: block;
}
</style>
