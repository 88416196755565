<template>
  <main>
    <n-space vertical>
      <span><b> Filtros</b></span>
      <n-grid x-gap="12" :cols="2">
        <n-gi>
          <n-space vertical>
            <h5 class="subtitle">Organización:</h5>
            <n-select
              :value="selectedOrg"
              filterable
              placeholder="Organizacion"
              :options="organizations"
              @update:value="setOrgChange"
            />
            <h5 class="subtitle">Estado:</h5>
            <n-select
              :value="selectedState"
              filterable
              placeholder="Estado"
              :options="states"
              @update:value="setStateChange"
            />
          </n-space>
        </n-gi>
        <n-gi>
          <n-space vertical>
            <h5 class="subtitle">Nombre o Codigo:</h5>
            <n-input
              v-model:value="nameSearch"
              type="text"
              placeholder="Nombre del curso"
              @update:value="setCourses"
            />
          </n-space>
        </n-gi>
      </n-grid>
      <hr />
      <div
        v-if="auth.can('create-courses')"
        style="display: flex; align-items: end; justify-content: end"
      >
        <n-button strong secondary type="success" @click="createModal()"
          >Añadir Curso</n-button
        >
      </div>
      <n-alert
        v-if="selectedClient && !ordersLoaded"
        title="Buscando Cursos"
        type="info"
        >Obteniendo listado de cursos <n-spin class="spinner" size="small"
      /></n-alert>
      <n-alert
        v-if="ordersLoaded && orders.length == 0"
        title="Sin Cursos"
        type="info"
        >No se encontraron cursos.</n-alert
      >
      <div v-if="orders.length >= 1">
        <n-data-table
          :columns="orderListColumns"
          :data="orders"
          :pagination="pagination"
          :bordered="true"
          :single-line="false"
        />
      </div>
    </n-space>
    <!-- MODALS -->
    <Teleport to="body">
      <CreateCourseModal
        :show="showCreateModal"
        @modalChange="CreateModalChange"
        @courseCreated="onCourseCreated"
      ></CreateCourseModal>
      <EditCourseModal
        :show="showEditModal"
        :course="currentCourse"
        @modalChange="EditModalChange"
        @courseEdited="OnCourseEdited"
      ></EditCourseModal>
      <ShowCourseElementsModal
        :show="showCourseElementsModal"
        :course="currentCourse"
        @modalChange="OnShowCourseElementsModalChange"
      ></ShowCourseElementsModal>
    </Teleport>
  </main>
</template>
<script lang="ts">
import api from "../providers/ApiProvider";
import auth from "../providers/AuthProvider";
import CreateCourseModal from "../components/CreateCourseModal.vue";
import EditCourseModal from "../components/EditCourseModal.vue";
import ShowCourseElementsModal from "../components/ShowCourseElementsModal.vue";
import Swal from "sweetalert2";
import { h } from "vue";
import { NButton, NTag } from "naive-ui";
export default {
  components: { CreateCourseModal, EditCourseModal, ShowCourseElementsModal },
  created() {
    api.getOrganizations().then((response) => {
      let list = response.data.data;
      this.organizations = this.prepareSelect(list);
      this.setupCourses();
    });
    this.orderListColumns = [
      {
        title: "#",
        key: "id",
        width: 45,
        align: "center",
      },
      {
        title: "Organización",
        key: "client.name",
        width: 130,
        render: this.renderOrgName,
      },
      {
        title: "SENCE",
        key: "sence_code",
        width: 100,
      },
      {
        title: "Nombre",
        key: "name",
      },
      {
        title: "Fecha",
        key: "created_at",
        width: 85,
        align: "center",
      },
      {
        title: "N° Pedidos",
        key: "orders_count",
        width: 125,
        align: "center",
      },
      {
        title: "Estado",
        key: "status",
        align: "center",
        width: 70,
        render(row) {
          return [
            h(
              NTag,
              {
                strong: true,
                tertiary: true,
                type: row.status != 1 ? "error" : "success",
              },
              { default: () => (row.status != 1 ? "Pendiente" : "Activo") }
            ),
          ];
        },
      },
      {
        title: "",
        key: "Funciones",
        render: function (this: any, row) {
          return [
            h(
              NButton,
              {
                strong: true,
                tertiary: true,
                size: "small",
                class: "spacer",
                onClick: function (this: any) {
                  this.showElementsModal(row);
                }.bind(this),
              },
              { default: () => "Ver Elementos" }
            ),
            !auth.can("update-courses")
              ? null
              : h(
                  NButton,
                  {
                    strong: true,
                    tertiary: true,
                    size: "small",
                    class: "spacer",
                    type: "info",
                    onClick: function (this: any) {
                      this.editModal(row);
                    }.bind(this),
                  },
                  { default: () => "Editar" }
                ),
            !auth.can("delete-courses")
              ? null
              : h(
                  NButton,
                  {
                    strong: true,
                    tertiary: true,
                    class: "spacer",
                    size: "small",
                    type: "error",
                    onClick: function (this: any) {
                      this.deleteModal(row);
                    }.bind(this),
                  },
                  { default: () => "Eliminar" }
                ),
          ];
        }.bind(this),
      },
    ];
  },
  data(): any {
    return {
      api: api,
      auth: auth,
      showCreateModal: false,
      showEditModal: false,
      showCourseElementsModal: false,
      currentCourse: null,
      ordersLoaded: false,
      selectedState: -1,
      selectedOrg: null,
      selectedOrder: null,
      nameSearch: "",
      organizations: [],
      clients: [{ value: -1, label: "Todos los clientes" }],
      orders: [],
      pagination: {
        pageSize: 10,
      },
      orderListColumns: [],
      states: [
        {
          value: -1,
          label: "Todos los estados",
        },
        {
          value: 0,
          label: "Pendiente",
        },
        {
          value: 1,
          label: "Activo",
        },
      ],
    };
  },
  methods: {
    renderOrgName(row) {
      return this.organizations.filter((x) => x.id == row.org_id)[0].name;
    },
    setOrgChange(v) {
      this.selectedOrg = v;
      this.setCourses();
    },
    setStateChange(v) {
      this.selectedState = v;
      this.setCourses();
    },
    createModal() {
      console.log("createModal");
      this.showCreateModal = true;
    },
    CreateModalChange(v) {
      this.showCreateModal = v;
    },
    EditModalChange(v) {
      this.showEditModal = v;
    },
    OnShowCourseElementsModalChange(v) {
      console.log("vaya vaya", v);
      this.showCourseElementsModal = v;
    },
    OnCourseEdited() {
      this.showEditModal = false;
      Swal.fire({
        title: "Curso actualizado!",
        text: "Se actualizo con exito el Curso.",
        icon: "success",
        didClose: () => {
          this.setCourses();
        },
      });
    },
    onCourseCreated() {
      this.showCreateModal = false;
      Swal.fire({
        title: "Curso creado!",
        text: "Se creo con exito el Curso.",
        icon: "success",
        didClose: () => {
          this.setCourses();
        },
      });
    },
    deleteModal(row) {
      Swal.fire({
        title: "¿Esta seguro?",
        text: "No podra revertir esto!, se borraran los reportes asociados a este curso.",
        icon: "warning",
        showCancelButton: true,
        confirmButtonText: "Si, borrar curso",
      }).then((result) => {
        if (result.isConfirmed) {
          api.deleteCourse(row.id).then((response) => {
            console.log(response);
            Swal.fire("Eliminado", "Se borro con exito", "success");
            this.setCourses();
          });
        }
      });
    },
    showElementsModal(row) {
      this.currentCourse = row;
      this.showCourseElementsModal = true;
      console.log(row);
    },
    editModal(row) {
      this.currentCourse = row;
      this.showEditModal = true;
    },
    setupCourses() {
      this.ordersLoaded = false;
      api.getCourses({
          withOrderCount: 1
        }).then((response) => {
        let list = response.data.data;
        this.orders = list;
        this.ordersLoaded = true;
      });
    },
    setCourses() {
      this.ordersLoaded = false;
      api
        .getCourses({
          organizationId: this.selectedOrg,
          nameSearch: this.nameSearch,
          status: this.selectedState,
          withOrderCount: 1,
        })
        .then((response) => {
          let list = response.data.data;
          this.orders = list;
          this.ordersLoaded = true;
        });
    },
    prepareSelect(data) {
      let final_list: any = [];
      data.forEach((org) => {
        org.label = org.name;
        org.value = org.id;
        final_list.push(org);
      });
      return final_list;
    },
  },
};
</script>
<style>
:root {
  --n-td-padding: 5px !important;
}
.n-data-table .n-data-table-td {
  padding: 5px !important;
}
.n-spin-body.spinner {
  float: right;
  margin-top: -15px;
}
.subtitle {
  margin: 10px 0 0 0;
}
.n-button.spacer {
  margin: 5px !important;
}
</style>
