<script setup lang="ts">
import api from "../providers/ApiProvider";
</script>
<template>
  <n-modal
    v-model:show="showVal"
    :on-update:show="
      (v: any) => {
        $emit('modalChange', v);
      }
    "
    class="create-order-modal"
    preset="dialog"
    title="Editar Orden"
    type="info"
    :bordered="false"
    size="huge"
    style="width: 650px"
    :segmented="true"
  >
    <n-space vertical>
      <label for="organizations">Organización <b class="red_color">*</b></label>
      <n-select
        v-model:value="org_id"
        filterable
        placeholder="Organización"
        :options="organizations"
        name="organizations"
        @update:value="updateOrg"
      />
      <label for="clients">Cliente <b class="red_color">*</b></label>
      <n-select
        v-model:value="client_id"
        filterable
        placeholder="Cliente"
        tag
        :options="clients"
        name="clients"
      />
      <label for="courses">Curso <b class="red_color">*</b></label>
      <n-select
        v-model:value="course_id"
        filterable
        placeholder="Curso"
        :options="FilteredCourses"
        name="courses"
      />

      <label for="OT">OT</label>
      <n-input
        v-model:value="code"
        name="OT"
        type="text"
        placeholder="Orden trabajo interna"
      />
      <label for="status">Estado</label>
      <n-switch v-model:value="status" />

      <div id="tavolo"></div>
      <n-alert
        v-if="!valid_data"
        title="Completa los campos requeridos para continuar"
        type="error"
      ></n-alert>
      <n-button strong secondary type="primary" @click="submitOrder">
        Editar Orden
      </n-button>
    </n-space>
  </n-modal>
</template>
<script lang="ts">
export default {
  emits: ["modalChange", "orderEdited"],
  created() {
    api.getOrganizations().then((response) => {
      let list = response.data.data;
      this.organizations = this.prepareSelect(list);
    });
    api.getCourses().then((response) => {
      let list = response.data.data;
      this.courses = this.prepareSelectCourse(list);
    });
  },
  props: ["show", "order"],
  data(): any {
    return {
      valid_data: true,
      status: false,
      col_index: [],
      client_id: null,
      org_id: null,
      course_id: null,
      organizations: [],
      clients: [],
      courses: [],
      rowscols: {},
      code: "",
    };
  },
  methods: {
    validateFields() {
      return (
        this.client_id !== null &&
        this.org_id !== null &&
        this.course_id !== null
      );
    },
    submitOrder() {
      this.valid_data = this.validateFields();
      if (this.valid_data) {
        let post_data = {
          course_id: this.course_id,
          org_id: this.org_id,
          client: this.client_id,
          code: this.code,
          status: this.status ? 1 : 0,
        };
        console.log(post_data);
        api.updateOrder(this.order.id, post_data).then((res) => {
          console.log(res);
          this.$emit("orderEdited", res);
        });
      }
    },
    updateOrg(o, v) {
      this.client_id = null;
      this.course_id = null;
      this.setClients(v.id, v);
    },
    setClients(v, o) {
      if (o) {
        this.clients = this.prepareSelect(o.clients);
      }
    },
    prepareSelectCourse(data) {
      let final_list: any = [];
      data.forEach((org) => {
        org.label =
          "(" +
          (org.sence_code ? org.sence_code : "Pendiente") +
          ") " +
          org.name;
        org.value = org.id;
        final_list.push(org);
      });
      return final_list;
    },
    prepareSelect(data) {
      let final_list: any = [];
      data.forEach((org) => {
        org.label = org.name;
        org.value = org.id;
        final_list.push(org);
      });
      return final_list;
    },
  },
  computed: {
    showVal() {
      return this.show;
    },
    FilteredCourses() {
      return this.courses.filter((e) => {
        return e.org_id == this.org_id;
      });
    },
  },
  watch: {
    order(v) {
      if (v) {
        let selected_org = this.order
          ? this.organizations.filter((x) => {
              console.log(this.order);
              return x.id == this.order.org_id;
            })[0]
          : null;
        if (selected_org) {
          this.org_id = selected_org.id;
          this.setClients(this.org_id, selected_org);
          this.client_id = this.order.client_id;
          this.code = this.order.code;
          this.status = this.order.status == 1;
          this.course_id = this.order ? this.order.course_id : null;
        }
      }
    },
  },
};
</script>
<style>
.red_color {
  color: red;
}

.minimal_select {
  width: 100% !important;
  background: #2080f030;
}

.excel_table td {
  border: 0.5px solid black;
}

.excel_table {
  border-collapse: collapse;
  max-width: 90vw;

  font-size: 10px !important;
}

.excel_table_container {
  max-width: 100%;
  overflow-x: auto;
}

.scheme_resume {
  height: calc(100% - 280px);
}

.scheme_resume .n-grid {
  height: calc(100% - 20px);
}

/*tr.invalid_row td {
  background: #d030507a !important;
  color: black !important;
}
tr.valid_row td {
  background: #18a05852 !important;
  color: black !important;
}*/
.small_alert.n-alert .n-alert-body {
  padding: 5px;
}

tr.invalid_row td {
  background: #d74f6b !important;
}

.n-dialog.n-modal {
  overflow-x: auto;
}

.resume_buttons {
  margin-top: 15px;
  border-top: 1px solid #9ea0a1;
  padding: 5px 10px 10px 0;
}

.resume_buttons button {
  margin-right: 10px;
}
</style>
