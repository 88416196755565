<script setup lang="ts">
import { RouterLink } from "vue-router";
import auth from "./providers/AuthProvider";
import { h } from "vue";
</script>

<template>
  <n-layout v-if="is_loged">
    <n-layout>
      <n-layout-header>
        <n-page-header>
          <template #title>
            {{ auth.user.name }}
          </template>
          <template #header>
            <n-breadcrumb>
              <n-breadcrumb-item>LRS</n-breadcrumb-item>
              <n-breadcrumb-item>{{ $route.name }}</n-breadcrumb-item>
            </n-breadcrumb>
          </template>
          <template #avatar>
            <n-avatar :src="auth.user.image" />
          </template>
          <template #extra>
            <n-space>
              <n-button @click="logout">Cerrar sesion</n-button>
            </n-space>
          </template>
        </n-page-header>
      </n-layout-header>
      <n-layout has-sider>
        <n-layout-sider content-style="padding: 10px;" width="200">
          <n-menu :options="menuOptions" :value="$route.path" />
        </n-layout-sider>
        <n-layout-content content-style="padding: 24px;">
          <RouterView @reload="reload" />
        </n-layout-content>
      </n-layout>
    </n-layout>
  </n-layout>
  <n-layout v-else><RouterView @reload="reload" /></n-layout>
</template>
<script lang="ts">
const menu_definition = [
  {
    path: "/",
    label: "Reporte",
    requires: "read-reports",
  },
  {
    path: "/organizations",
    label: "Organizaciones",
    requires: "read-organizations",
  },
  {
    path: "/orders",
    label: "Ordenes",
    requires: "read-orders",
  },
  {
    path: "/clients",
    label: "Clientes",
    requires: "read-clients",
  },
  {
    path: "/courses",
    label: "Cursos",
    requires: "read-courses",
  },
];
function setupMenu() {
  let options : any[] = [];
  menu_definition.forEach((menu) => {
    if (auth.can(menu.requires)) {
      options.push({
        label: () =>
          h(
            RouterLink,
            {
              to: {
                path: menu.path,
              },
            },
            { default: () => menu.label }
          ),
        key: menu.path,
      });
    }
  });
  return options;
}
export default {
  data() :any {
    return {
      menuOptions: setupMenu(),
      is_loged: auth.isLoggedIn(),
    };
  },
  methods: {
    reload() {
      console.log("reloading");
      this.is_loged = true;
      this.menuOptions = setupMenu();
    },
    logout() {
      auth.logout();
      this.is_loged = false;
      this.$router.replace("/login");
    },
  },
};
</script>
<style>
/*@import "@/assets/base.css";*/
.n-layout-header {
  padding: 24px;
  background: #4ea2cb;
}
.n-layout-header * {
  color: white !important;
}
.n-breadcrumb .n-breadcrumb-item:last-child .n-breadcrumb-item__link {
  font-weight: bold;
}
</style>
