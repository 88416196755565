import { createApp } from "vue";
import App from "./App.vue";
import router from "./router";
import naive from 'naive-ui'
import 'sweetalert2/dist/sweetalert2.min.css';

const app = createApp(App);
app.use(naive);
app.use(router);

app.mount("#app");
