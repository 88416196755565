<script setup lang="ts">
import api from "../providers/ApiProvider";
</script>
<template>
  <main>
    <h3>Generar reporte</h3>
    <h5>Seleccione una organización:</h5>
    <n-select
      filterable
      :value="selectedOrg"
      placeholder="Organizacion"
      :options="organizations"
      @update:value="setClients"
    />
    <h5>Seleccione un cliente:</h5>
    <n-select
      filterable
      placeholder="Cliente"
      :options="clients"
      @update:value="setOrders"
    />
    <n-alert
      v-if="selectedClient && !ordersLoaded"
      title="Buscando Ordenes"
      type="info"
      >Obteniendo listado de ordenes asociadas a cliente
      <n-spin class="spinner" size="small"
    /></n-alert>
    <n-alert
      v-if="ordersLoaded && orders.length == 0"
      title="Sin Ordenes"
      type="info"
      >El cliente seleccionado no tiene ordenes asociadas.</n-alert
    >
    <div v-if="orders.length >= 1">
      <h5>Ordenes:</h5>
      <n-data-table
        :columns="orderListColumns"
        :data="orders"
        :pagination="pagination"
        :bordered="false"
      />
    </div>
  </main>
</template>
<script lang="ts">
import { h } from "vue";
import { NButton } from "naive-ui";
export default {
  created() {
    api.getOrganizations().then((response) => {
      let list = response.data.data;
      this.organizations = this.prepareSelect(list);
      if (this.organizations.length == 1) {
        this.selectedOrg = this.organizations[0].id;
        this.setClients(this.selectedOrg, this.organizations[0]);
      }
    });
  },
  data(): any {
    return {
      ordersLoaded: false,
      selectedOrg: null,
      selectedClient: null,
      selectedOrder: null,
      organizations: [],
      clients: [],
      orders: [],
      pagination: {
        pageSize: 10,
      },
      orderListColumns: [
        {
          title: "#",
          key: "id",
        },
        {
          title: "Cliente",
          key: "client.name",
        },
        {
          title: "Curso",
          key: "course.name",
        },
        {
          title: "Fecha",
          key: "created_at",
        },
        {
          title: "",
          key: "Reporte",
          render(row) {
            return [h(
              NButton,
              {
                strong: true,
                tertiary: false,
                size: "small",
                onClick: () => {
                  window.open(
                    "https://api.adistancia.app/reports/download/all?order=" + row.id
                  );
                },
              },
              { default: () => "Reporte" }
            ), (row.course.has_quiz ? h(
              NButton,
              {
                strong: true,
                tertiary: true,
                size: "small",
                onClick: () => {
                  window.open(
                    "https://api.adistancia.app/reports/download/quiz?order=" + row.id
                  );
                },
              },
              { default: () => "Encuesta" }
            ) : null)]
          },
        },
      ],
    };
  },
  methods: {
    setClients(v, o) {
      this.selectedOrg = o.id;
      if (o) {
        this.orders = [];
        this.clients = this.prepareSelect(o.clients);
      }
    },
    setOrders(v, o) {
      this.selectedClient = o.id;
      this.ordersLoaded = false;
      api.getOrders({ clientId: o.id }).then((response) => {
        let list = response.data.data;
        this.orders = list;
        this.ordersLoaded = true;
      });
    },
    prepareSelect(data) {
      let final_list: any = [];
      data.forEach((org) => {
        org.label = org.name;
        org.value = org.id;
        final_list.push(org);
      });
      return final_list;
    },
  },
};
</script>
<style scoped>
.n-spin-body.spinner {
  float: right;
  margin-top: -15px;
}
</style>
