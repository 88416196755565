import axios from "axios";
import auth from "./AuthProvider";
import type { AxiosRequestConfig } from "axios";
const service = axios.create({
  timeout: 20000, // request timeout
});

// request interceptor

service.interceptors.request.use(
  (config: AxiosRequestConfig) => {
    // Do something before request is sent
    if (config.headers) {
      config.headers["Authorization"] = "Bearer " + auth.getToken();
    }
    return config;
  },
  (error) => {
    Promise.reject(error);
  }
);
const API_URL = "https://api.adistancia.app/api/";
//const API_URL = "http://localhost:8000/api/";
class ApiProvider {
  public getOrganizations() {
    return service.get(API_URL + "organizations");
  }
  public getClients() {
    return service.get(API_URL + "clients");
  }
  public getOrder(id, is_setup = false) {
    return service.get(
      API_URL + "orders/" + id + (is_setup ? "?is_setup=1" : "")
    );
  }
  public deleteOrder(id) {
    return service.delete(API_URL + "orders/" + id);
  }
  public getOrders(data = {}) {
    return service.get(API_URL + "orders", { params: data });
  }
  public createOrder(data = {}) {
    return service.post(API_URL + "orders", data);
  }
  public updateOrder(order_id, data = {}) {
    return service.put(API_URL + "orders/" + order_id, data);
  }
  public getCourses(data = {}) {
    return service.get(API_URL + "courses", { params: data });
  }
  public getCourse(id) {
    return service.get(API_URL + "courses/" + id);
  }
  public createCourse(data = {}) {
    return service.post(API_URL + "courses", data);
  }
  public deleteCourse(id) {
    return service.delete(API_URL + "courses/" + id);
  }
}
export default new ApiProvider();
