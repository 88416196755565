<script setup lang="ts">
import api from "../providers/ApiProvider";
import { h } from "vue";
import { NTag } from "naive-ui";
</script>
<template>
  <n-modal
    v-model:show="showVal"
    :on-update:show="
      (v: any) => {
        $emit('modalChange', v);
        raw_participants = [];
        loaded = false;
      }
    "
    class="create-order-modal"
    preset="dialog"
    title="Listado Participantes"
    type="info"
    :bordered="false"
    size="huge"
    style="width: 650px"
    :segmented="true"
  >
    <n-space vertical>
      <label for="filter">Filtrar:</label>
      <n-input
        v-model:value="filterText"
        type="text"
        placeholder="Nombre o RUT"
      />
      <div style="text-align: center">
        <n-spin size="small" v-if="!loaded" />
      </div>
      <n-data-table
        v-if="loaded"
        class="clean_table"
        :columns="table_columns"
        :data="participants"
        :pagination="{ pageSize: 10 }"
        striped
      />
    </n-space>
  </n-modal>
</template>
<script lang="ts">
export default {
  emits: ["modalChange"],
  props: ["show", "order"],
  data() {
    return {
      filterText: "",
      raw_participants: [],
      loaded: false,
      table_columns: [
        {
          title: "RUT",
          key: "code",
        },
        {
          title: "Nombre",
          key: "name",
        },
        {
          title: "Ultima conexión",
          render(row) {
            return [
              h(
                NTag,
                {
                  strong: true,
                  tertiary: true,
                  type: row.last_sync != null ? "success" : "error",
                },
                {
                  default: () =>
                    row.last_sync != null ? row.last_sync : "Pendiente",
                }
              ),
            ];
          },
        },
      ],
    };
  },
  methods: {
    getOrderDetail(id) {
      api.getOrder(id).then((response) => {
        let data = response.data.data;
        this.raw_participants = data.participants;
        console.log(data);
        this.loaded = true;
      });
    },
  },
  computed: {
    showVal() {
      return this.show;
    },
    participants() {
      if (this.filterText.length >= 3) {
        let arr = this.raw_participants.filter((x: { name; code }) => {
          let lowered = this.filterText.toLowerCase();
          return (
            x.name.toLowerCase().indexOf(lowered) !== -1 ||
            x.code.toLowerCase().indexOf(lowered) !== -1
          );
        });
        return arr;
      } else {
        return this.raw_participants;
      }
    },
  },
  watch: {
    order(v) {
      if (v) {
        this.getOrderDetail(v.id);
      }
    },
  },
};
</script>
<style>
.red_color {
  color: red;
}

.minimal_select {
  width: 100% !important;
  background: #2080f030;
}

.excel_table td {
  border: 0.5px solid black;
}

.excel_table {
  border-collapse: collapse;
  max-width: 90vw;

  font-size: 10px !important;
}

.excel_table_container {
  max-width: 100%;
  overflow-x: auto;
}

.scheme_resume {
  height: calc(100% - 280px);
}

.scheme_resume .n-grid {
  height: calc(100% - 20px);
}

/*tr.invalid_row td {
  background: #d030507a !important;
  color: black !important;
}
tr.valid_row td {
  background: #18a05852 !important;
  color: black !important;
}*/
.small_alert.n-alert .n-alert-body {
  padding: 5px;
}

tr.invalid_row td {
  background: #d74f6b !important;
}

.n-dialog.n-modal {
  overflow-x: auto;
}

.resume_buttons {
  margin-top: 15px;
  border-top: 1px solid #9ea0a1;
  padding: 5px 10px 10px 0;
}

.resume_buttons button {
  margin-right: 10px;
}
</style>
