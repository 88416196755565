<script setup lang="ts">
import api from "../providers/ApiProvider";
import { h } from "vue";
import { NTag } from "naive-ui";
</script>
<template>
  <n-modal
    v-model:show="showVal"
    :on-update:show="
      (v: any) => {
        $emit('modalChange', v);
        raw_elements = [];
        loaded = false;
      }
    "
    class="create-order-modal"
    preset="dialog"
    title="Listado Elementos"
    type="info"
    :bordered="false"
    size="huge"
    style="width: 650px"
    :segmented="true"
  >
    <n-space vertical>
      <label for="filter">Filtrar:</label>
      <n-input v-model:value="filterText" type="text" placeholder="Nombre" />
      <div style="text-align: center">
        <n-spin size="small" v-if="!loaded" />
      </div>
      <n-data-table
        v-if="loaded"
        class="clean_table"
        :columns="table_columns"
        :data="elements"
        :pagination="{ pageSize: 10 }"
        striped
      />
    </n-space>
  </n-modal>
</template>
<script lang="ts">
export default {
  emits: ["modalChange"],
  props: ["show", "course"],
  data() {
    return {
      filterText: "",
      raw_elements: [],
      loaded: false,
      table_columns: [
        {
          title: "Nombre",
          key: "name",
        },
        {
          title: "Tipo",
          key: "type",
        },
        {
          title: "",
          render(row) {
            return row.is_final || row.is_diagno
              ? [
                  h(
                    NTag,
                    {
                      strong: true,
                      tertiary: true,
                      type: "info",
                    },
                    {
                      default: () => {
                        if (row.is_final) {
                          return "Final " + row.weight * 100 + "%";
                        } else if (row.is_diagno) {
                          return "Diagnostico";
                        }
                      },
                    }
                  ),
                ]
              : null;
          },
        },
      ],
    };
  },
  methods: {
    getCourseDetail(id) {
      api.getCourse(id).then((response) => {
        let data = response.data.data;
        this.raw_elements = data.resources;
        console.log(data);
        this.loaded = true;
      });
    },
  },
  computed: {
    showVal() {
      return this.show;
    },
    elements() {
      if (this.filterText.length >= 3) {
        let arr = this.raw_elements.filter((x: { name; code }) => {
          let lowered = this.filterText.toLowerCase();
          return x.name.toLowerCase().indexOf(lowered) !== -1;
        });
        return arr;
      } else {
        return this.raw_elements;
      }
    },
  },
  watch: {
    course(v) {
      if (v) {
        this.getCourseDetail(v.id);
      }
    },
  },
};
</script>
<style>
.red_color {
  color: red;
}

.minimal_select {
  width: 100% !important;
  background: #2080f030;
}

.excel_table td {
  border: 0.5px solid black;
}

.excel_table {
  border-collapse: collapse;
  max-width: 90vw;

  font-size: 10px !important;
}

.excel_table_container {
  max-width: 100%;
  overflow-x: auto;
}

.scheme_resume {
  height: calc(100% - 280px);
}

.scheme_resume .n-grid {
  height: calc(100% - 20px);
}

/*tr.invalid_row td {
  background: #d030507a !important;
  color: black !important;
}
tr.valid_row td {
  background: #18a05852 !important;
  color: black !important;
}*/
.small_alert.n-alert .n-alert-body {
  padding: 5px;
}

tr.invalid_row td {
  background: #d74f6b !important;
}

.n-dialog.n-modal {
  overflow-x: auto;
}

.resume_buttons {
  margin-top: 15px;
  border-top: 1px solid #9ea0a1;
  padding: 5px 10px 10px 0;
}

.resume_buttons button {
  margin-right: 10px;
}
</style>
