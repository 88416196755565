import axios from "axios";
const API_URL = "https://api.adistancia.app/api/";
//const API_URL = "http://localhost:8000/api/";
class AuthProvider {
  public user;
  public is_loged = false;
  public login(user) {
    return new Promise<string>((resolve, reject) => {
      axios
        .post(API_URL + "login", {
          email: user.email,
          password: user.password,
        })
        .then(
          ((response) => {
            if (response.data.data.token.accessToken) {
              localStorage.setItem("user", JSON.stringify(response.data.data));
              this.is_loged = true;
              this.user = response.data.data;
              resolve(this.user.name);
            } else {
              reject(response.data.data.error);
            }
            return response.data.data;
          }).bind(this)
        )
        .catch((res) => {
          reject(res);
        });
    });
  }
  public logout() {
    localStorage.removeItem("user");
    this.user = {};
    this.is_loged = false;
  }
  private checkLocalStorageLogin() {
    if (!this.is_loged && localStorage.getItem("user") !== null) {
      this.is_loged = true;
      const json_raw = localStorage.getItem("user");
      this.user = JSON.parse(json_raw ? json_raw : "");
    }
  }
  public isLoggedIn(): boolean {
    this.checkLocalStorageLogin();
    return this.is_loged;
  }
  public can(scopes) {
    this.checkLocalStorageLogin();
    console.log("check", scopes);
    if (!this.is_loged) return false;
    const exploded_scopes: any = scopes.split(",");
    let valid = true;
    exploded_scopes.forEach(
      ((scope) => {
        if (valid) {
          valid = this.user.token.token.scopes.indexOf(scope) !== -1;
          console.log(valid, scope);
        }
      }).bind(this)
    );
    return valid;
  }
  public getToken() {
    return this.user.token ? this.user.token.accessToken : null;
  }
}
export default new AuthProvider();
